import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";

import {Box} from "@mui/material";
import {IWastyRecipient} from "../../../../../utils/interfaces/special_wasty/wasty_recipient/IWastyRecipient";
import useApiCreateWastyRecipient from "./api-hooks/useApiCreateWastyRecipient";
import useApiUpdateWastyRecipient from "./api-hooks/useApiUpdateWastyRecipient";
import useApiGetWastyRecipient from "./api-hooks/useApiGetWastyRecipient";
import MuiInputTextField from "../../../../ui/mui/mui-input/MuiInputTextField";
import MuiFabButtonBack from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import MuiFabButtonSaveAs from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSaveAs";
import MuiFabButtonSave from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSave";


interface Props {
    objToEdit: IWastyRecipient | null | undefined;
    onClose: () => void;
    onSelect?: (obj: IWastyRecipient | undefined) => void,
}

const WastyRecipientForm: React.FC<Props> = ({objToEdit, onClose, onSelect}) => {
    const {t} = useTranslation()


    const [name, setName] = useState<string>();


    const createMutation = useApiCreateWastyRecipient();
    const updateMutation = useApiUpdateWastyRecipient();

    const {
        data: detailedObj,
    } = useApiGetWastyRecipient(objToEdit?.id);


    useEffect(() => {
        if (detailedObj) {
            setName(detailedObj.name);
        }
    }, [detailedObj]);


    const handleSubmit = async () => {
        try {
            const payload = {
                name: name,
            };

            let newData;

            if (detailedObj !== null && detailedObj !== undefined) {
                newData = await updateMutation.mutateAsync({id: detailedObj.id, data: payload});
            } else {
                newData = await createMutation.mutateAsync(payload);
            }
            onClose();
            onSelect && onSelect(newData);

        } catch (error) {
        }
    }


    return (
        <Box sx={{padding: 2}}>
            <form>
                <MuiInputTextField
                    onChange={(e) => setName(e.target.value)}
                    label='name'
                    type="text"
                    value={name}
                    required={true}
                />


                <Box style={{display: 'flex', justifyContent: 'center'}} className={'mt-2 gap-x-2'}>
                    <MuiFabButtonBack onClick={onClose}/>
                    {/*{objToEdit &&*/}
                    {/*    <MuiFabButtonDelete onClick={() => {*/}
                    {/*        setConfirmationOpen(true)*/}
                    {/*    }}/>}*/}
                    {objToEdit ?
                        <MuiFabButtonSaveAs onClick={handleSubmit}/>
                        :
                        <MuiFabButtonSave onClick={handleSubmit}/>}
                </Box>
            </form>
        </Box>
    );
};

export default WastyRecipientForm;