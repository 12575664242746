import React, {useEffect, useMemo, useState} from 'react';
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {
    MaterialReactTable,
    MRT_ActionMenuItem,
    MRT_ColumnDef,
    MRT_Row,
    MRT_RowSelectionState,
    useMaterialReactTable
} from "material-react-table";
import {Delete, Edit} from "@mui/icons-material";
import {Avatar, Box, Grid, Popover} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import {CSVLink} from "react-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {IDocPayment} from "../../../../../../utils/interfaces/my_documents/doc_payment/IDocPayment";
import useApiDeleteDocPayment from "./api-hooks/useApiDeleteDocPayment";
import useApiGetDocPayments from "./api-hooks/useApiGetDocPayments";
import {IDocDetail} from "../../../../../../utils/interfaces/my_documents/doc/IDocDetail";
import useCustomCellRenderer from "./useCustomCellRenderer";
import Papa from "papaparse";
import {
    getMuiTableBodyCellProps,
    getMuiTableBodyRowProps,
    getMuiTableHeadCellProps,
    getMuiTablePaperProps
} from "../../../../../ui/mui/mui_react_table/MuiReactTableStyles";
import MuiReadOnlyBoxBody from "../../../../../ui/mui/mui-display-data/MuiReadOnlyBoxBody";
import MRTMuiButton from "../../../../../ui/mui/mui_react_table/MRTMuiButton";
import Loading from "../../../../../ui/Loading";
import MuiAlertDialog from "../../../../../ui/mui/mui_modal/MuiAlertDialog";
import MuiFetchingSpinner from "../../../../../ui/mui/MuiFetchingSpinner";
import PaymentIcon from "@mui/icons-material/Payment";
import MuiFabButtonBack from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import FiscalRegistryForm from "../../fiscal_registry/FiscalRegistryForm";
import MuiModal from "../../../../../ui/mui/mui_modal/MuiModal";
import {useQueryClient} from "@tanstack/react-query";
import DocPaymentForm from "./DocPaymentForm";
import useApiGetRicevuteBancarie from "../../ricevute_bancarie/api-hooks/useApiGetRicevuteBancarie";
import {MdMerge} from "react-icons/md";
import {formatDate} from "../../../../../../logics/helpers";
import useApiGetDoc from "../doc/api-hooks/useApiGetDoc";
import useApiUnifyRicevuteBancarie from "../../ricevute_bancarie/api-hooks/useApiUnifyRicevuteBancarie";
import {RiAiGenerate} from "react-icons/ri";
import RicevuteBancarieCreationForm from "../../ricevute_bancarie/RicevuteBancarieCreationForm";


interface IDocPaymentWithCustomField extends IDocPayment {
    custom_field: JSX.Element;
}

interface Props {
    onSelect?: (obj: IDocPayment | undefined) => void,
    doc?: IDocDetail,
    onClose?: () => void;
}


const DocPaymentTable = ({onSelect, onClose, doc}: Props) => {
    const theme = useTheme();
    const {t} = useTranslation()
    const queryClient = useQueryClient();


    const [selectedRow, setSelectedRow] = useState<IDocPayment | undefined>()
    const [selectedRows, setSelectedRows] = useState<IDocPayment[] | undefined>()
    const [rows, setRows] = useState<IDocPayment[] | undefined>()

    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)
    const [isCreateRicevuteFormOpen, setIsCreateRicevuteBancarieFormOpen] = useState<boolean>(false)

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const deleteMutation = useApiDeleteDocPayment(selectedRow?.id);
    const {mutateAsync: apiDelete} = deleteMutation;
    const [isFiscalRegistryFormOpen, setIsFiscalRegistryFormOpen] = useState<boolean>(false)

    const unifyMutation = useApiUnifyRicevuteBancarie()
    const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
    const handleUnifyRicevuteBancarie = async (ricevuteBancarie: MRT_Row<IDocPayment>[]) => {
        const originalDataIds = ricevuteBancarie.map(row => row.original.id);
        await unifyMutation.mutateAsync({data: originalDataIds}); // Wrap in an object
    }

    const handleCreateRicevuteBancarieFile = async () => {
        setIsCreateRicevuteBancarieFormOpen(true)
    }


    const handleConfirmDelete = async () => {
        try {
            if (onSelect && (doc?.id === selectedRow?.id)) {
                onSelect(undefined);
            }
            await apiDelete({pk: selectedRow?.id});
        } catch (error) {
        }
        setDeleteConfirmationOpen(false);
        await queryClient.invalidateQueries({queryKey: ['useApiGetDocPayment', doc?.id, selectedRow?.id]});
        await queryClient.invalidateQueries({queryKey: ["useApiGetDocPayments", doc?.id]});
    };
    const handleCancelDelete = () => {
        setDeleteConfirmationOpen(false);
    };

    const handleToggleFiscalRegistryForm = async () => {
        setIsFiscalRegistryFormOpen(!isFiscalRegistryFormOpen);
    }

    const {
        data: docDetailed,
    } = useApiGetDoc(selectedRow?.doc?.id, null);


    const {
        data: docPayments,
        isLoading: isLoadingDocPayments,
        isFetching: isFetchingDocPayments,
    } = useApiGetDocPayments(doc?.id);

    const {
        data: ricevuteBancarie,
        isLoading: isLoadingRicevuteBancarie,
        isFetching: isFetchingRicevuteBancarie,
    } = useApiGetRicevuteBancarie();


    useEffect(() => {
        if (docPayments) {
            setRows(docPayments);
        }
        if (!doc && ricevuteBancarie) {
            setRows(ricevuteBancarie);
        }
    }, [doc, docPayments, ricevuteBancarie]);

    // note_marco: customCellRenderer in hook example
    const CustomCellRenderer: React.FC<{ row: IDocPayment, theme: any }> = ({row, theme}) => {
        const {renderIcons} = useCustomCellRenderer(row, theme, handleToggleFiscalRegistryForm);
        return renderIcons();
    };


    const [objsWithCustomField, setObjsWithCustomField] = useState<IDocPaymentWithCustomField[] | undefined>();
    useEffect(() => {
        const dataWithCustomField: IDocPaymentWithCustomField[] | undefined = rows?.map((row) => ({
            ...row,
            custom_field: <CustomCellRenderer row={row} theme={theme}/>
        }));
        setObjsWithCustomField(dataWithCustomField);
    }, [rows, theme, docPayments, ricevuteBancarie]);


    // note_marco: export csv example
    const convertArrayOfObjectsToCSV = (data: IDocPayment[]): string => {
        // Create new array of objects without the unnecessary fields
        const reducedData = data.map(({created_at, updated_at, doc, ...rest}) => rest);

        // Convert to CSV
        return Papa.unparse(reducedData, {
            delimiter: ';',
        });
    };


    const columns = useMemo<MRT_ColumnDef<IDocPayment>[]>(
        () => {
            const baseColumns: MRT_ColumnDef<IDocPayment>[] = [
                {
                    accessorKey: 'custom_field',
                    header: '',
                    enableColumnActions: false,
                    enableColumnFilter: false,
                    enableColumnOrdering: false,
                    enableSorting: false,
                },

                {
                    header: t('doc'),
                    accessorFn: (row) => row.doc?.company_doc_type.doc_type.name + ' - ' + row.doc?.doc_number + '/' + row.doc?.doc_prefix
                },
                {
                    header: t('doc_date'),
                    accessorFn: (row) => formatDate(row.doc?.doc_date)
                },
                {
                    header: t('payment_date'),
                    accessorFn: (row) => formatDate(row.payment_date)
                },
                {
                    header: t('value'),
                    accessorFn: (row) => row.value.toFixed(2) + " €",
                },
                {
                    accessorKey: 'description',
                    header: t('description'),

                },
                {
                    accessorKey: 'abi',
                    header: t('abi'),
                    accessorFn: (row) => (row.doc?.fiscal_registry.bank ? row.doc?.fiscal_registry.bank.abi : ''),

                },
                {
                    accessorKey: 'cab',
                    header: t('cab'),
                    accessorFn: (row) => (row.doc?.fiscal_registry.bank ? row.doc?.fiscal_registry.bank.cab : ''),

                },
            ];


            if (doc) {
                baseColumns.push({
                    accessorKey: 'doc_type',
                    header: t('payment type'),
                    accessorFn: (row) => row.payment_type.name,
                });
            }

            if (!doc) {
                baseColumns.push({
                    accessorKey: 'registry',
                    header: t('registry'),
                    accessorFn: (row) => row.doc?.fiscal_registry.registry?.business_name,
                });
            }

            return baseColumns;
        },
        [doc, t]
    );


    const table = useMaterialReactTable<IDocPayment>({
        columns,
        defaultColumn: {
            maxSize: 400,
            minSize: 80,
            size: 80,
        },
        data: objsWithCustomField ? objsWithCustomField : [],
        columnFilterDisplayMode: 'popover',
        globalFilterFn: 'contains',
        enableFullScreenToggle: false,
        enableFilters: false,
        enableDensityToggle: false,
        muiTableHeadCellProps: getMuiTableHeadCellProps(theme.palette.primary.main),
        muiTableBodyCellProps: getMuiTableBodyCellProps(theme),
        muiTablePaperProps: getMuiTablePaperProps,
        enablePagination: false,
        initialState: {
            density: 'compact',
            pagination: {pageSize: 25, pageIndex: 0},
        },
        mrtTheme: (theme) => ({
            baseBackgroundColor: theme.palette.backgroundAppGarageColor.main,

        }),
        muiTableBodyRowProps: getMuiTableBodyRowProps(theme),

        enableRowActions: true,
        renderRowActionMenuItems: ({closeMenu, row}) => [
            onSelect && onClose && (
                <MRT_ActionMenuItem
                    icon={<AdsClickIcon color="primary"/>}
                    key="select"
                    label={t("select")}
                    onClick={() => {
                        onSelect(row.original);
                        onClose();
                    }}
                    table={table}
                />),
            <MRT_ActionMenuItem
                icon={<Edit color="primary"/>}
                key="edit"
                label={t("edit")}
                onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    setSelectedRow(row.original);
                    setIsEditFormOpen(true)
                    closeMenu()
                }}
                table={table}
            />,
            <MRT_ActionMenuItem
                icon={<Delete color="warning"/>}
                key="delete"
                label={t("delete")}
                onClick={() => {
                    setSelectedRow(row.original);
                    setDeleteConfirmationOpen(true)
                    closeMenu()
                }}
                table={table}
                disabled={!!row.original.locked_at}
            />,
        ],
        renderTopToolbarCustomActions: ({table}) => (
            <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                <>
                    <Avatar sx={{
                        backgroundColor: 'white',
                        border: `2px solid ${theme.palette.primary.main}`,
                        marginRight: 1
                    }}>
                        <PaymentIcon color={'primary'}/>
                    </Avatar>
                    <MuiReadOnlyBoxBody label={''} value={doc ? 'payments' : 'ricevute bancarie'}/>
                    {doc &&
                        <MRTMuiButton
                            icon={<AddIcon style={{color: theme.palette.primary.main, fontSize: '2rem'}}/>}
                            onClick={() => {
                                setSelectedRow(undefined);
                                setIsEditFormOpen(true)
                            }} label={'add_doc_payment'}/>}
                    {rows &&
                        <CSVLink data={convertArrayOfObjectsToCSV(rows)} filename={'all_rows.csv'}>
                            {<FileDownloadIcon
                                style={{color: theme.palette.primary.main, fontSize: '2rem', marginTop: 8}}/>}
                        </CSVLink>
                    }
                    {table.getSelectedRowModel().rows.length > 0 &&
                        <MuiReadOnlyBoxBody label={'Total:'} value={selectedRowsTotalValue.toFixed(2) + " €"}/>
                    }
                    {table.getSelectedRowModel().rows.length > 1 && isMergeable &&
                        <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                            <MRTMuiButton
                                bigAtRender={true}
                                icon={<MdMerge style={{color: theme.palette.primary.main, fontSize: '2rem'}}/>}
                                onClick={() => {
                                    handleUnifyRicevuteBancarie(table.getSelectedRowModel().rows)
                                }}
                                label={t('unisci riba')}/>
                        </Box>
                    }
                    {table.getSelectedRowModel().rows.length > 0 &&
                        <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                            <MRTMuiButton
                                bigAtRender={true}
                                icon={<RiAiGenerate style={{color: theme.palette.primary.main, fontSize: '2rem'}}/>}
                                onClick={() => {
                                    handleCreateRicevuteBancarieFile()
                                }}
                                label={t('genera riba')}/>
                        </Box>
                    }
                </>
            </Box>
        ),
        enableRowSelection: !doc,
        positionToolbarAlertBanner: 'none',
        onRowSelectionChange: setRowSelection,
        state: {rowSelection},
    });

    const [isMergeable, setIsMergeable] = useState(false)
    useEffect(() => {
        const items = table.getSelectedRowModel().rows as MRT_Row<IDocPayment>[];

        if (items.length > 0) {
            const fiscalRegistryIds = items.map(item => item.original.doc?.fiscal_registry.id);
            const paymentDates = items.map(item => item.original.payment_date);

            const allFiscalRegistrySame = fiscalRegistryIds.every(id => id === fiscalRegistryIds[0]);
            const allPaymentDatesSame = paymentDates.every(date => date === paymentDates[0]);

            setIsMergeable(allFiscalRegistrySame && allPaymentDatesSame);
        } else {
            setIsMergeable(false);
        }
    }, [rowSelection, table]);

    const [selectedRowsTotalValue, setSelectedRowsTotalValue] = useState(0)
    useEffect(() => {
        const items = table.getSelectedRowModel().rows as MRT_Row<IDocPayment>[];

        if (items.length > 0) {
            const totalValue = items.reduce((sum, item) => sum + (item.original.value || 0), 0);
            setSelectedRowsTotalValue(totalValue);
        } else {
            setSelectedRowsTotalValue(0);
        }
    }, [rowSelection, table]);


    return <>
        {(doc && isLoadingDocPayments) || (!doc && isLoadingRicevuteBancarie) ? <Loading/> :
            <>
                {onClose &&
                    <Grid item xs={12} style={{textAlign: 'center'}}>
                        <Box className={'mt-2 gap-x-2 mb-2'}>
                            <MuiFabButtonBack onClick={onClose}/>
                        </Box>
                    </Grid>}

                <MaterialReactTable table={table}/>

                <MuiAlertDialog
                    open={isDeleteConfirmationOpen}
                    title="delete_confirmation"
                    content="ask_for_delete"
                    onClose={handleCancelDelete}
                    onConfirm={handleConfirmDelete}
                />

                <Popover
                    id={'edit_popover'}
                    open={isEditFormOpen}
                    // anchorEl={anchorEl}
                    onClose={() => setIsEditFormOpen(false)}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                    }}
                >
                    {(doc || docDetailed) && (
                        <DocPaymentForm
                            objToEdit={selectedRow || null}
                            onClose={() => setIsEditFormOpen(false)}
                            doc={doc ?? docDetailed as IDocDetail}
                        />
                    )}


                </Popover>

                <MuiModal
                    top="50%"
                    left="50%"
                    width={'120vh'}
                    open={isFiscalRegistryFormOpen}
                    disableBackdropClick={true}
                    onClose={() => setIsFiscalRegistryFormOpen(false)}
                >
                    <FiscalRegistryForm
                        objToEdit={doc?.fiscal_registry}
                        onClose={() => setIsFiscalRegistryFormOpen(false)}
                    />
                </MuiModal>

                <MuiModal
                    top="50%"
                    left="50%"
                    width={'120vh'}
                    open={isCreateRicevuteFormOpen}
                    disableBackdropClick={true}
                    onClose={() => setIsCreateRicevuteBancarieFormOpen(false)}
                >
                    <RicevuteBancarieCreationForm
                        doc_payments={table.getSelectedRowModel().rows.map(row => row.original)}
                        onClose={() => setIsCreateRicevuteBancarieFormOpen(false)}
                    />
                </MuiModal>


                {((doc && isFetchingDocPayments) || (!doc && isFetchingRicevuteBancarie)) &&
                    <MuiFetchingSpinner text="Fetching data..."/>
                }
            </>}
    </>;
};

export default DocPaymentTable;
