import React, {useEffect, useMemo, useState} from 'react';
import {ITanneryDyeingRecipe} from "../../../../../../utils/interfaces/tannery_dyeing/ITanneryDyeingRecipe";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import {useToast} from "../../../../../../utils/contexts/toast-context";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {useQueryClient} from "@tanstack/react-query";
import {useTanneryDyeing} from "../../../../../../utils/contexts/tannery_dyeing/tannery_dyeing-context";
import useApiGetTanneryDyeingRecipe from "./api-hooks/useApiGetTanneryDyeingRecipe";
import {CustomAxiosError} from "../../../../../../logics/api_config";
import {ITanneryDyeingSchedule} from "../../../../../../utils/interfaces/tannery_dyeing/ITanneryDyeingSchedule";
import useApiDeleteTanneryDyeingRecipe from "./api-hooks/useApiDeleteTanneryDyeingRecipe";
import customCellRenderer from "./customCellRenderer";
import {MaterialReactTable, MRT_ColumnDef, MRT_Row, useMaterialReactTable} from "material-react-table";
import {
    fadeIn,
    getMuiTableBodyCellProps,
    getMuiTableFooterCellProps,
    getMuiTableHeadCellProps,
    getMuiTablePaperProps
} from "../../../../../ui/mui/mui_react_table/MuiReactTableStyles";
import {Avatar, Box, Popover} from "@mui/material";
import MuiReadOnlyBoxBody from "../../../../../ui/mui/mui-display-data/MuiReadOnlyBoxBody";
import MuiAlertDialog from "../../../../../ui/mui/mui_modal/MuiAlertDialog";
import PanToolIcon from '@mui/icons-material/PanTool';
import TanneryDyeingRecipeForm from "./TanneryDyeingRecipeForm";
import useApiMoveTanneryDyeingRecipe from "./api-hooks/useApiMoveTanneryDyeingRecipe";
import Draggable from 'react-draggable';
import MuiTypography from "../../../../../ui/mui/mui-display-data/MuiTypography";
import MRTMuiButton from "../../../../../ui/mui/mui_react_table/MRTMuiButton";
import AddIcon from "@mui/icons-material/Add";
import {formatDateTimeToDateTimeString} from "../../../../../../logics/helpers";
import IconButton from "@mui/material/IconButton";
import {MdDone} from "react-icons/md";
import useApiToggleCompletedAtTanneryDyeingRecipe from "./api-hooks/useApiToggleCompletedAtTanneryDyeingRecipe";
import {Edit} from "@mui/icons-material";


interface Props {
    tannery_dyeing: ITanneryDyeingSchedule,
}

interface ITanneryDyeingRecipeWithCustomField extends ITanneryDyeingRecipe {
    custom_field: JSX.Element;
}

const TanneryDyeingRecipeTable = ({tannery_dyeing}: Props) => {
    const {displayError, displayMessage, displayWarning} = useToast();
    const {selectedCompany, userLevel} = useAuth();
    const theme = useTheme();
    const {t} = useTranslation()
    const queryClient = useQueryClient();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const {selectTanneryDyeing} = useTanneryDyeing()
    const [isDragging, setIsDragging] = useState(false);
    const {selectTanneryDyeingRecipe, selectedTanneryDyeingRecipe, isEditable, avatarImage} = useTanneryDyeing()

    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)
    const [isCreateFormOpen, setIsCreateFormOpen] = useState<boolean>(false)


    const {
        data,
        error,
        isLoading
    } = useApiGetTanneryDyeingRecipe(selectedCompany?.company_token, tannery_dyeing.id);
    if (error) {
        const axiosError = error as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }


    const [rows, setRows] = useState<ITanneryDyeingRecipe[] | undefined>()
    const [selectedRow, setSelectedRow] = useState<ITanneryDyeingRecipe | undefined>()

    const updateMutation = useApiToggleCompletedAtTanneryDyeingRecipe(selectedCompany?.company_token);
    const handleToggleCompletedAtClick = async (rowData: ITanneryDyeingRecipe) => {
        await updateMutation.mutateAsync({
            tannery_dyeing_id: tannery_dyeing?.id,
            id: rowData.id,
        });
    };


    useEffect(() => {
        if (data) {
            setRows(data);
        }
    }, [data]);


    const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const deleteMutation = useApiDeleteTanneryDyeingRecipe(selectedCompany?.company_token, tannery_dyeing?.id, selectedRow?.id);
    const {mutateAsync: apiDelete} = deleteMutation;

    const handleConfirmDelete = async () => {
        if (!isEditable) {
            displayWarning(t('not_modifiable'))
            return;
        }
        try {

            await apiDelete({
                companyToken: selectedCompany?.company_token,
                tannery_dyeing_id: tannery_dyeing?.id,
                pk: selectedRow?.id
            });
            // displayMessage('Recipe Row deleted successfully');

        } catch (error) {
            const axiosError = error as CustomAxiosError;
            if (axiosError.response && axiosError.response.data && axiosError.response.data.detail) {
                displayError(axiosError.response.data.detail);
            } else {
                displayError('An error occurred while deleting the Recipe Row ');
            }
        }
        setDeleteConfirmationOpen(false);
        await queryClient.invalidateQueries({queryKey: ['getTanneryDyeingRecipe']});
    };
    const handleCancelDelete = () => {
        setDeleteConfirmationOpen(false);
    };


    const [tanneryDyeingRecipesWithCustomField, setTanneryDyeingRecipesWithCustomField] = useState<ITanneryDyeingRecipeWithCustomField[] | undefined>();
    useEffect(() => {
        const dataWithCustomField: ITanneryDyeingRecipeWithCustomField[] | undefined = rows?.map((row) => ({
            ...row,
            custom_field: customCellRenderer(row, theme),
        }));
        setTanneryDyeingRecipesWithCustomField(dataWithCustomField);
    }, [rows]);


    const moveMutation = useApiMoveTanneryDyeingRecipe(selectedCompany?.company_token);

    const [validationErrors, setValidationErrors] = useState<
        Record<string, string | undefined>
    >({});
    const validateRequired = (value: string) => !!value.length;

    const totalEurPerQl = useMemo(() => {
        return data?.reduce((acc, curr) => acc + (curr.eur_per_ql ?? 0), 0) ?? 0;
    }, [data]);


    const columns = useMemo<MRT_ColumnDef<ITanneryDyeingRecipe>[]>(
        () => [


            {
                accessorFn: (row) => {
                    return row.percentage ? row.percentage.toFixed(2) : '';
                },
                header: t('%'),
                enableColumnFilter: false,
                muiTableBodyCellProps: {
                    sx: {
                        // backgroundColor: theme.palette.common.white,
                        borderBottom: `1px solid ${theme.palette.primary.light}`,
                        borderLeft: `1px solid ${theme.palette.primary.light}`,
                        borderRight: `1px solid ${theme.palette.primary.light}`,
                        fontSize: '1.1rem'
                    },
                },
                muiEditTextFieldProps: ({cell, row}) => ({
                    type: 'text',
                    required: true,
                    error: !!validationErrors?.[cell.id],
                    helperText: validationErrors?.[cell.id],
                    //store edited user in state to be saved later
                    onBlur: (event) => {
                        const validationError = !validateRequired(event.currentTarget.value)
                            ? 'Required'
                            : undefined;
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: validationError,
                        });
                        // setTanneryDyeingRecipesWithCustomField({
                        //     ...tanneryDyeingRecipesWithCustomField,
                        //     [row.id]: row.original
                        // });
                    },
                }),
            },
            {
                accessorFn: (row) => {
                    return row.kgs ? row.kgs : '';
                },
                header: t('kgs'),
                enableColumnFilter: false,
                sx: {
                    // backgroundColor: theme.palette.common.white,
                    borderBottom: `1px solid ${theme.palette.primary.light}`,
                    borderLeft: `1px solid ${theme.palette.primary.light}`,
                    borderRight: `1px solid ${theme.palette.primary.light}`,
                    fontSize: '1.1rem'
                },
            },


            {
                accessorKey: 'product_name',
                header: t('chemical_product'),
                sx: {
                    // backgroundColor: theme.palette.common.white,
                    borderLeft: `1px solid ${theme.palette.primary.light}`,
                },
            },

            {
                accessorKey: 'rotations',
                header: t('rot'),
                enableColumnFilter: false,
                muiTableBodyCellProps: {
                    sx: {
                        // backgroundColor: theme.palette.common.white,
                        // borderRight: `1px solid ${theme.palette.primary.light}`,
                        borderBottom: `1px solid ${theme.palette.primary.light}`,
                        borderLeft: `1px solid ${theme.palette.primary.light}`,
                        fontSize: '1.1rem'
                    },
                },
            },


            {
                accessorKey: 'temperature',
                header: t('°C'),
                enableColumnFilter: false,
                muiTableBodyCellProps: {
                    sx: {
                        // backgroundColor: theme.palette.common.white,
                        borderBottom: `1px solid ${theme.palette.primary.light}`,
                        borderLeft: `1px solid ${theme.palette.primary.light}`,
                        fontSize: '1.1rem'
                    },
                },

            },
            {
                accessorKey: 'ph',
                header: t('pH'),
                enableColumnFilter: false,
                muiTableBodyCellProps: {
                    sx: {
                        // backgroundColor: theme.palette.backgroundAppGarageColor.dark,
                        borderBottom: `1px solid ${theme.palette.primary.light}`,
                        borderLeft: `1px solid ${theme.palette.primary.light}`,
                        fontSize: '1.1rem'
                    },
                },

            },
            {
                accessorFn: (row) => {
                    return row.description ? row.description : '\u00A0';
                },
                header: t('description'),
                muiTableBodyCellProps: {
                    sx: {
                        // backgroundColor: theme.palette.common.white,
                        borderRight: `1px solid ${theme.palette.primary.light}`,
                        borderBottom: `1px solid ${theme.palette.primary.light}`,
                        borderLeft: `1px solid ${theme.palette.primary.light}`,
                        fontSize: '1.1rem'
                    },
                },

            },
            {
                // note_marco: hide column conditionally: here hide the toggle, and the set columnVisibility in initial
                // state (use accessorKey as column name)
                accessorFn: (row) => {
                    return row.eur_per_ql ? row.eur_per_ql.toFixed(2) : '';
                },
                accessorKey: 'eur_per_ql',
                header: t('eur_per_ql'),
                enableColumnFilter: false,
                aggregationFn: 'sum',
                Footer: () => <div>{totalEurPerQl.toFixed(2)}</div>,
                sx: {
                    // backgroundColor: theme.palette.common.white,
                    borderBottom: `1px solid ${theme.palette.primary.light}`,
                    borderLeft: `1px solid ${theme.palette.primary.light}`,
                    fontSize: '1.1rem'
                },
                visibleInShowHideMenu: (userLevel >= 99),
            },

            {
                header: t('completed at'),
                accessorFn: (row) => row.completed_at ? formatDateTimeToDateTimeString(row.completed_at) : null,
                sx: {
                    // backgroundColor: theme.palette.common.white,
                    borderBottom: `1px solid ${theme.palette.primary.light}`,
                    borderLeft: `1px solid ${theme.palette.primary.light}`,
                    borderRight: `1px solid ${theme.palette.primary.light}`,
                    fontSize: '1.1rem'
                },
            },
            {
                accessorKey: 'is_completed',
                header: t('is_completed'),
                enableColumnActions: false,
                enableColumnFilter: false,
                enableColumnOrdering: false,
                enableSorting: false,
                Cell: ({row}) => (
                    row.original.rotations ? (
                        <IconButton
                            aria-label="info"
                            onClick={() => handleToggleCompletedAtClick(row.original)}
                            sx={{padding: 0, margin: 0, minWidth: 0}}
                        >
                            <MdDone
                                color={row.original.is_completed ? theme.palette.primary.main : theme.palette.action.disabled}
                            />
                        </IconButton>
                    ) : null
                ),
            },
            {
                accessorKey: 'edit_field',
                header: '',
                enableColumnActions: false,
                enableColumnFilter: false,
                enableColumnOrdering: false,
                enableSorting: false,
                Cell: ({row}) => (
                    <IconButton aria-label={t("edit")}
                                sx={{
                                    padding: '0px', '&:hover': {
                                        backgroundColor: 'transparent',
                                    },
                                }}
                                disabled={!isEditable}
                                onClick={(event) => {
                                    setAnchorEl(event.currentTarget);
                                    selectTanneryDyeing(tannery_dyeing);
                                    setSelectedRow(row.original);
                                    setIsEditFormOpen(true)
                                }}>
                        <Edit color={isEditable ? 'primary' : 'disabled'}/>
                    </IconButton>
                ),
                muiTableBodyCellProps: {
                    sx: {
                        // backgroundColor: theme.palette.common.white,
                        borderBottom: `1px solid ${theme.palette.primary.light}`,
                        // borderLeft: `1px solid ${theme.palette.primary.light}`,
                        // borderRight: `1px solid ${theme.palette.primary.light}`,
                        fontSize: '1.1rem'
                    },
                },
            },

        ],
        [data]
    );

    const handleRowClick = (row: any) => {
        selectTanneryDyeingRecipe(row.original);
        setSelectedRow(row.original);
        // setIsEditFormOpen(true);
    };


    const table = useMaterialReactTable<ITanneryDyeingRecipe>({
        columns,
        defaultColumn: {
            maxSize: 400,
            minSize: 0,
            size: 40,
        },
        data: tanneryDyeingRecipesWithCustomField ? tanneryDyeingRecipesWithCustomField : [],
        columnFilterDisplayMode: 'popover',
        globalFilterFn: 'contains',
        enableFullScreenToggle: false,
        enablePagination: !isEditable,
        enableFilters: true,
        enableColumnActions: false,
        enableDensityToggle: true,
        enableHiding: true,
        enableGlobalFilter: false,
        enableRowOrdering: isEditable,
        muiRowDragHandleProps: ({table}) => ({
            onDragEnd: async () => {
                const {draggingRow, hoveredRow} = table.getState();
                if (hoveredRow && draggingRow && tanneryDyeingRecipesWithCustomField) {
                    tanneryDyeingRecipesWithCustomField.splice(
                        (hoveredRow as MRT_Row<ITanneryDyeingRecipe>).index,
                        0,
                        tanneryDyeingRecipesWithCustomField.splice(draggingRow.index, 1)[0],
                    );
                    setTanneryDyeingRecipesWithCustomField([...tanneryDyeingRecipesWithCustomField]);
                    await moveMutation.mutateAsync({
                        pk: draggingRow.original.id,
                        tannery_dyeing_id: draggingRow.original.tannery_dyeing,
                        row_position: hoveredRow.index ? hoveredRow.index + 1 : 0
                    });
                }
            },
        }),
        muiTableBodyRowProps: ({row}) => ({
            onClick: () => handleRowClick(row),
            sx: {
                position: 'relative',
                cursor: 'pointer',
                color: theme.palette.textAppGarageColor.tw700,
                fontFamily: 'Arial Rounded MT Regular',
                animation: `${fadeIn} 1s ease`,
                "&:hover": {
                    backgroundColor: `${theme.palette.tableHoverColor.main} !important`,
                },
                backgroundColor:
                    row.original.is_completed
                        ? theme.palette.backgroundAppGarageColor.dark    // If row is completed, use a success color
                        : row.original === selectedTanneryDyeingRecipe
                            ? theme.palette.action.selected // Selected row background color
                            : 'inherit',                    // Default background color
            },
        }),

        enableSorting: false,
        enableStickyHeader: false,
        muiTableHeadCellProps: getMuiTableHeadCellProps(theme.palette.primary.main),
        muiTableFooterCellProps: getMuiTableFooterCellProps(theme, '1.1rem'),
        muiTableBodyCellProps: getMuiTableBodyCellProps(theme, '1.1rem'),
        muiTablePaperProps: getMuiTablePaperProps,
        initialState: {
            density: 'compact',
            pagination: {pageSize: 50, pageIndex: 0},
            expanded: true,
            columnVisibility: {
                eur_per_ql: userLevel >= 99,
            }
        },
        mrtTheme: (theme) => ({
            baseBackgroundColor: theme.palette.backgroundAppGarageColor.main,

        }),


        renderTopToolbarCustomActions: ({table}) => (
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <Avatar sx={{
                    backgroundColor: 'white',
                    border: `2px solid ${theme.palette.primary.main}`,
                    marginRight: 1
                }}>
                    {avatarImage}
                </Avatar>
                <MuiReadOnlyBoxBody label={''} value={'tannery_dyeing_recipe'}/>
                {isEditable &&
                    <MRTMuiButton
                        icon={<AddIcon style={{color: theme.palette.primary.main, fontSize: '2rem'}}/>}
                        onClick={() => {
                            setSelectedRow(undefined);
                            setIsCreateFormOpen(!isCreateFormOpen);
                        }} label={'add_tannery_dyeing'}/>}
            </Box>
        ),

    });


    return <>

        <>

            <MaterialReactTable table={table}/>

            <MuiAlertDialog
                open={isDeleteConfirmationOpen}
                title="delete_confirmation"
                content="ask_for_delete"
                onClose={handleCancelDelete}
                onConfirm={handleConfirmDelete}
            />


            <Popover
                id={'edit_popover'}
                open={isEditFormOpen}
                onClose={() => setIsEditFormOpen(false)}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
            >
                <Box sx={{
                    // width: '45vh',
                    // height: '25vh',
                    background: theme.palette.backgroundAppGarageColor.main,
                    border: `2px solid ${theme.palette.primary.main}`,
                    boxShadow: 24,
                    borderRadius: '4px',
                    p: 2,
                    zIndex: 9999
                }}>
                    <TanneryDyeingRecipeForm
                        objToEdit={selectedRow}
                        onClose={() => setIsEditFormOpen(false)}
                    />
                </Box>
            </Popover>

            {isCreateFormOpen && (
                <Draggable
                    axis="both"
                    handle=".handle"
                    defaultPosition={{x: 0, y: 0}}
                    grid={[25, 25]}
                    scale={1}
                    onStart={() => setIsDragging(true)}
                    onStop={() => setIsDragging(false)}
                >
                    <Box sx={{
                        position: 'fixed',
                        top: '20%',
                        left: '70%',
                        // width: '45vh',
                        // height: '45vh',
                        transform: 'translate(-50%, -50%)',
                        background: theme.palette.backgroundAppGarageColor.main,
                        border: `2px solid ${theme.palette.primary.main}`,
                        boxShadow: 24,
                        borderRadius: '4px',
                        p: 2,
                        zIndex: 100
                    }}>
                        <Box
                            className="handle"
                            sx={{
                                cursor: isDragging ? 'grabbing' : 'grab',
                                backgroundColor: theme.palette.primary.main,
                                padding: '1px',
                                borderRadius: '1px 1px 0 0',
                            }}
                        >
                        <span style={{
                            display: 'flex',
                            alignItems: 'center',
                            backgroundColor: theme.palette.background.paper,
                            padding: '5px',
                        }}>
                            <PanToolIcon color="primary"/>
                            <MuiTypography value={t('drag_the_tannery_recipe_row_creator')}/>
                        </span>
                        </Box>
                        <TanneryDyeingRecipeForm
                            objToEdit={undefined}
                            onClose={() => setIsCreateFormOpen(false)}
                        />
                    </Box>
                </Draggable>

            )}


        </>
    </>;
};

export default TanneryDyeingRecipeTable;
