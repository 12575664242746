import React, {useEffect, useMemo, useState} from 'react';
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {MaterialReactTable, MRT_ColumnDef, useMaterialReactTable} from "material-react-table";
import {Avatar, Box} from "@mui/material";
import Grid from '@mui/material/Grid';
import {useQueryClient} from "@tanstack/react-query";
import {
    getMuiTableBodyCellProps,
    getMuiTableBodyRowProps,
    getMuiTableHeadCellProps,
    getMuiTablePaperProps
} from "../../../../ui/mui/mui_react_table/MuiReactTableStyles";
import Loading from "../../../../ui/Loading";
import MuiFabButtonBack from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import MuiFetchingSpinner from "../../../../ui/mui/MuiFetchingSpinner";
import useApiGetOpenLoads from "./api-hooks/useApiGetOpenLoads";
import {IMyCompanySite} from "../../../../../utils/interfaces/my_company/my_company_site/IMyCompanySite";
import {IOpenLoad} from "../../../../../utils/interfaces/special_wasty/IOpenLoad";
import useCustomCellRenderer from "./useCustomCellRenderer";
import MuiReadOnlyBoxBody from "../../../../ui/mui/mui-display-data/MuiReadOnlyBoxBody";
import {IoMdOpen} from "react-icons/io";
import {formatDateTimeToDateString} from "../../../../../logics/helpers";


interface IObjWithCustomField extends IOpenLoad {
    custom_field: JSX.Element;
}

interface Props {
    onClose?: () => void;
    my_company_site: IMyCompanySite | undefined,
    open_loads?: IOpenLoad[],

}


const OpenLoadsTable = ({open_loads, onClose, my_company_site}: Props) => {
    const theme = useTheme();
    const {t} = useTranslation()
    const queryClient = useQueryClient();


    const [selectedRow, setSelectedRow] = useState<IOpenLoad | undefined>()
    const [selectedRows, setSelectedRows] = useState<IOpenLoad[] | undefined>()
    const [rows, setRows] = useState<IOpenLoad[] | undefined>()


    const {
        data,
        isLoading,
        isFetching,
    } = useApiGetOpenLoads(my_company_site?.id);


    useEffect(() => {
        if (open_loads) {
            setRows(open_loads);
        } else if (data) {
            setRows(data);
        }
    }, [data, open_loads]);

    // note_marco: customCellRenderer in hook example
    const CustomCellRenderer: React.FC<{ row: IOpenLoad, theme: any }> = ({row, theme}) => {
        const {renderIcons} = useCustomCellRenderer(row, theme);
        return renderIcons();
    };


    const [objsWithCustomField, setObjsWithCustomField] = useState<IObjWithCustomField[] | undefined>();
    useEffect(() => {
        const dataWithCustomField: IObjWithCustomField[] | undefined = rows?.map((row) => ({
            ...row,
            custom_field: <CustomCellRenderer row={row} theme={theme}/>
        }));
        setObjsWithCustomField(dataWithCustomField);
    }, [rows, theme]);


    const columns = useMemo<MRT_ColumnDef<IOpenLoad>[]>(
        () => {
            const baseColumns: MRT_ColumnDef<IOpenLoad>[] = [
                {
                    accessorKey: 'custom_field',
                    header: '',
                    enableColumnActions: false,
                    enableColumnFilter: false,
                    enableColumnOrdering: false,
                    enableSorting: false,
                },
                {
                    accessorKey: 'name',
                    header: t('year'),
                    accessorFn: (row) => (row.waste_load.movement_date ? new Date(row.waste_load.movement_date).getFullYear() : ''),
                },
                {
                    header: t('movement date'),
                    accessorFn: (row) => (row.waste_load.movement_date ? formatDateTimeToDateString(row.waste_load.movement_date) : ''),
                },
                {
                    header: t('movement number'),
                    accessorFn: (row) => row.waste_load.movement_number
                },
                {
                    header: t('remaining estimated weight'),
                    accessorFn: (row) => row.remaining_estimated_weight + " kg"
                },
                {
                    accessorKey: 'waste_code',
                    header: t('waste code'),
                    accessorFn: (row) => row.waste_load.waste_code.code
                },

            ];


            // if (doc) {
            //     baseColumns.push({
            //         accessorKey: 'doc_type',
            //         header: t('payment type'),
            //         accessorFn: (row) => row.payment_type.name,
            //     });
            // }
            //
            // if (!doc) {
            //     baseColumns.push({
            //         accessorKey: 'registry',
            //         header: t('registry'),
            //         accessorFn: (row) => row.doc?.fiscal_registry.registry?.business_name,
            //     });
            // }

            return baseColumns;
        },
        [t]
    );


    const table = useMaterialReactTable<IOpenLoad>({
        columns,
        defaultColumn: {
            maxSize: 400,
            minSize: 80,
            size: 80,
        },
        data: objsWithCustomField ? objsWithCustomField : [],
        columnFilterDisplayMode: 'popover',
        globalFilterFn: 'contains',
        enableFullScreenToggle: false,
        enableFilters: false,
        enableDensityToggle: false,
        muiTableHeadCellProps: getMuiTableHeadCellProps(theme.palette.primary.main),
        muiTableBodyCellProps: getMuiTableBodyCellProps(theme),
        muiTablePaperProps: getMuiTablePaperProps,
        enablePagination: false,
        initialState: {
            density: 'compact',
            pagination: {pageSize: 25, pageIndex: 0},
            columnVisibility: {
                waste_code: open_loads?.length == 0,
            }
        },
        mrtTheme: (theme) => ({
            baseBackgroundColor: theme.palette.backgroundAppGarageColor.main,

        }),
        muiTableBodyRowProps: getMuiTableBodyRowProps(theme),
        renderTopToolbarCustomActions: ({table}) => (
            <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                <>
                    <Avatar sx={{
                        backgroundColor: 'white',
                        border: `2px solid ${theme.palette.primary.main}`,
                        marginRight: 1
                    }}>
                        <IoMdOpen color={theme.palette.primary.main}/>
                    </Avatar>
                    <MuiReadOnlyBoxBody label={''} value={'open loads'}/>
                </>
            </Box>
        ),
    });


    return <>
        {isLoading ? <Loading/> :
            <>
                {onClose &&
                    <Grid item xs={12} style={{textAlign: 'center'}}>
                        <Box className={'mt-2 gap-x-2 mb-2'}>
                            <MuiFabButtonBack onClick={onClose}/>
                        </Box>
                    </Grid>
                }

                <MaterialReactTable table={table}/>


                {isFetching &&
                    <MuiFetchingSpinner text="Fetching data..."/>
                }
            </>}
    </>;
};

export default OpenLoadsTable;
