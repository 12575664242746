import React from 'react';
import MaxWidthDiv from "../../../../ui/MaxWitdthDiv";
import MyCompanySiteSelect from "../../my_company/my_company_site/MyCompanySiteSelect";
import {IMyCompanySite} from "../../../../../utils/interfaces/my_company/my_company_site/IMyCompanySite";
import {Box} from "@mui/material";
import {useAppGarage} from "../../../../../utils/contexts/appGarage-context";
import OpenLoadsTable from "./OpenLoadsTable";


const WasteCodeScreen = () => {
    const {selectMyCompanySite, selectedMyCompanySite} = useAppGarage()

    const handleMyCompanySiteChange = (obj: IMyCompanySite | undefined) => {
        selectMyCompanySite(obj);
    }

    return (
        <>
            <MaxWidthDiv maxWidth='1024px'>
                <Box sx={{alignItems: 'center', width: '100%', marginTop: 4}}>
                    <MyCompanySiteSelect obj={undefined} onChange={handleMyCompanySiteChange} className={''}
                                         withDefaultValueIfEmpty/>
                </Box>

                {selectedMyCompanySite && <OpenLoadsTable my_company_site={selectedMyCompanySite}/>}

            </MaxWidthDiv>
        </>
    );
};

export default WasteCodeScreen;
