import React from 'react';
import MaxWidthDiv from "../../../../ui/MaxWitdthDiv";
import WastyCarrierTable from "./WastyCarrierTable";


const WastyCarrierScreen = () => {


    return (
        <>
            <MaxWidthDiv maxWidth='1024px'>
                <WastyCarrierTable/>
            </MaxWidthDiv>
        </>
    );
};

export default WastyCarrierScreen;
