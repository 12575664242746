import React from 'react';
import MaxWidthDiv from "../../../../ui/MaxWitdthDiv";
import WastyRecipientTable from "./WastyRecipientTable";


const WastyRecipientScreen = () => {


    return (
        <>
            <MaxWidthDiv maxWidth='1024px'>
                <WastyRecipientTable/>
            </MaxWidthDiv>
        </>
    );
};

export default WastyRecipientScreen;
