import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";

import {Box, Grid} from "@mui/material";
import {IWasteCode} from "../../../../../../utils/interfaces/special_wasty/waste_code/IWasteCode";
import useApiCreateWasteCode from "./useApiCreateWasteCode";
import {IMyCompanySite} from "../../../../../../utils/interfaces/my_company/my_company_site/IMyCompanySite";
import useApiUpdateWasteCode from "./useApiUpdateWasteCode";
import useApiGetWasteCode from "./useApiGetWasteCode";
import MuiInputTextField from "../../../../../ui/mui/mui-input/MuiInputTextField";
import MuiFabButtonBack from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import MuiFabButtonSaveAs from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSaveAs";
import MuiFabButtonSave from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSave";
import {formatDateTimeForInput} from "../../../../../../logics/helpers";


interface Props {
    objToEdit: IWasteCode | null | undefined;
    my_company_site: IMyCompanySite | undefined,
    onClose: () => void;
    onSelect?: (obj: IWasteCode | undefined) => void,
}

const WastyCodeForm: React.FC<Props> = ({objToEdit, onClose, onSelect, my_company_site}) => {
    const {t} = useTranslation()

    const [code, setCode] = useState<string>();
    const [description, setDescription] = useState<string>();
    const [physicalState, setPhysicalState] = useState<string>();
    const [disposalCode, setDisposalCode] = useState<string>();
    const [recoveryCode, setRecoveryCode] = useState<string>();
    const [hazardousClass, setHazardousClass] = useState<string>();
    const [internalNotes, setInternalNotes] = useState<string>();
    const [testExpireAt, setTestExpireAt] = useState<string>();

    const {
        data: detailedObj,
    } = useApiGetWasteCode(objToEdit?.id);


    useEffect(() => {
        if (detailedObj) {
            setCode(detailedObj.code);
            setDescription(detailedObj.description);
            setPhysicalState(detailedObj.physical_state);
            setDisposalCode(detailedObj.disposal_code);
            setRecoveryCode(detailedObj.recovery_code);
            setHazardousClass(detailedObj.hazardous_class);
            setInternalNotes(detailedObj.internal_notes);
            setTestExpireAt(detailedObj.test_expire_at);
        }
    }, [detailedObj]);


    const createMutation = useApiCreateWasteCode(my_company_site?.id);
    const updateMutation = useApiUpdateWasteCode(my_company_site?.id);


    const handleSubmit = async () => {
        try {
            const payload = {
                code: code,
                description: description,
                physical_state: physicalState,
                disposal_code: disposalCode,
                recovery_code: recoveryCode,
                hazardous_class: hazardousClass,
                internal_notes: internalNotes,
                test_expire_at: testExpireAt,
            };

            let newData;

            if (detailedObj !== null && detailedObj !== undefined) {
                newData = await updateMutation.mutateAsync({id: detailedObj.id, data: payload});
            } else {
                newData = await createMutation.mutateAsync(payload);
            }
            onClose();
            onSelect && onSelect(newData);

        } catch (error) {
        }
    }


    return (
        <Box sx={{padding: 0}}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                    <MuiInputTextField
                        onChange={(e) => setCode(e.target.value)}
                        label='waste code'
                        type="text"
                        value={code}
                        required={true}
                    />
                </Grid>

                <Grid item xs={12} sm={4}>
                    <MuiInputTextField
                        onChange={(e) => setDescription(e.target.value)}
                        label='description'
                        type="text"
                        value={description}
                    />
                </Grid>

                <Grid item xs={12} sm={4}>
                    <MuiInputTextField
                        onChange={(e) => setPhysicalState(e.target.value)}
                        label='physical state'
                        type="text"
                        value={physicalState}
                    />
                </Grid>

                <Grid item xs={12} sm={4}>
                    <MuiInputTextField
                        onChange={(e) => setDisposalCode(e.target.value)}
                        label='disposal code'
                        type="text"
                        value={disposalCode}
                    />
                </Grid>

                <Grid item xs={12} sm={4}>
                    <MuiInputTextField
                        onChange={(e) => setRecoveryCode(e.target.value)}
                        label='recovery code'
                        type="text"
                        value={recoveryCode}
                    />
                </Grid>

                <Grid item xs={12} sm={4}>
                    <MuiInputTextField
                        onChange={(e) => setHazardousClass(e.target.value)}
                        label='hazardous class'
                        type="text"
                        value={hazardousClass}
                    />
                </Grid>

                <Grid item xs={12} sm={4}>
                    <MuiInputTextField
                        onChange={(e) => setInternalNotes(e.target.value)}
                        label='internal notes'
                        type="text"
                        value={internalNotes}
                    />
                </Grid>

                <Grid item xs={12} sm={4}>
                    <MuiInputTextField
                        onChange={(e) => setTestExpireAt(e.target.value)}
                        label='test expire at'
                        type="date"
                        value={testExpireAt ? formatDateTimeForInput(testExpireAt) : '0'}
                    />
                </Grid>
            </Grid>


            <Box style={{display: 'flex', justifyContent: 'center'}} className={'mt-2 gap-x-2'}>
                <MuiFabButtonBack onClick={onClose}/>
                {/*{objToEdit &&*/}
                {/*    <MuiFabButtonDelete onClick={() => {*/}
                {/*        setConfirmationOpen(true)*/}
                {/*    }}/>}*/}
                {objToEdit ?
                    <MuiFabButtonSaveAs onClick={handleSubmit}/>
                    :
                    <MuiFabButtonSave onClick={handleSubmit}/>}
            </Box>
        </Box>
    );
};

export default WastyCodeForm;