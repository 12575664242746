import React, {useEffect, useMemo, useState} from 'react';
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {MaterialReactTable, MRT_ActionMenuItem, MRT_ColumnDef, useMaterialReactTable} from "material-react-table";
import {Delete, Edit} from "@mui/icons-material";
import {Avatar, Box} from "@mui/material";
import Grid from '@mui/material/Grid';
import AddIcon from "@mui/icons-material/Add";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import {useQueryClient} from "@tanstack/react-query";
import {
    getMuiTableBodyCellProps,
    getMuiTableBodyRowProps,
    getMuiTableHeadCellProps,
    getMuiTablePaperProps
} from "../../../../ui/mui/mui_react_table/MuiReactTableStyles";
import MuiReadOnlyBoxBody from "../../../../ui/mui/mui-display-data/MuiReadOnlyBoxBody";
import MRTMuiButton from "../../../../ui/mui/mui_react_table/MRTMuiButton";
import Loading from "../../../../ui/Loading";
import MuiFabButtonBack from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import MuiAlertDialog from "../../../../ui/mui/mui_modal/MuiAlertDialog";
import MuiFetchingSpinner from "../../../../ui/mui/MuiFetchingSpinner";
import {IWasteCode} from "../../../../../utils/interfaces/special_wasty/waste_code/IWasteCode";
import {IMyCompanySite} from "../../../../../utils/interfaces/my_company/my_company_site/IMyCompanySite";
import useApiDeleteWasteCode from "./api-hooks/useApiDeleteWasteCode";
import useApiGetWasteCodes from "./api-hooks/useApiGetWasteCodes";
import useCustomCellRenderer from "./useCustomCellRenderer";
import {FaRecycle} from "react-icons/fa";
import WastyCodeForm from "./api-hooks/WastyCodeForm";
import MuiModal from "../../../../ui/mui/mui_modal/MuiModal";
import {formatDateTimeToDateString} from "../../../../../logics/helpers";
import MuiBorderedBox from "../../../../ui/mui/MuiBorderedBox";
import OpenLoadsTable from "../open_loads/OpenLoadsTable";


interface IObjWithCustomField extends IWasteCode {
    custom_field: JSX.Element;
}

interface Props {
    onSelect?: (obj: IWasteCode | undefined) => void,
    my_company_site: IMyCompanySite | undefined,
    onClose?: () => void;
}


const WasteCodeTable = ({onSelect, onClose, my_company_site}: Props) => {
    const theme = useTheme();
    const {t} = useTranslation()
    const queryClient = useQueryClient();

    const [selectedRow, setSelectedRow] = useState<IWasteCode | undefined>()
    const [selectedRows, setSelectedRows] = useState<IWasteCode[] | undefined>()
    const [rows, setRows] = useState<IWasteCode[] | undefined>()

    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)


    const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const deleteMutation = useApiDeleteWasteCode(selectedRow?.id);
    const {mutateAsync: apiDelete} = deleteMutation;
    const [isFiscalRegistryFormOpen, setIsFiscalRegistryFormOpen] = useState<boolean>(false)


    const handleConfirmDelete = async () => {
        try {
            if (onSelect) {
                onSelect(undefined);
            }
            await apiDelete({pk: selectedRow?.id});
        } catch (error) {
        }
        setDeleteConfirmationOpen(false);
        await queryClient.invalidateQueries({queryKey: ['useApiGetWasteCode', selectedRow?.id]});
        await queryClient.invalidateQueries({queryKey: ["useApiGetWasteCodes"]});
    };
    const handleCancelDelete = () => {
        setDeleteConfirmationOpen(false);
    };

    const handleToggleFiscalRegistryForm = async () => {
        setIsFiscalRegistryFormOpen(!isFiscalRegistryFormOpen);
    }


    const {
        data,
        isLoading,
        isFetching,
    } = useApiGetWasteCodes(my_company_site?.id);


    useEffect(() => {
        if (data) {
            setRows(data);
        }
    }, [data]);

    // note_marco: customCellRenderer in hook example
    const CustomCellRenderer: React.FC<{ row: IWasteCode, theme: any }> = ({row, theme}) => {
        const {renderIcons} = useCustomCellRenderer(row, theme, handleToggleFiscalRegistryForm);
        return renderIcons();
    };


    const [objsWithCustomField, setObjsWithCustomField] = useState<IObjWithCustomField[] | undefined>();
    useEffect(() => {
        const dataWithCustomField: IObjWithCustomField[] | undefined = rows?.map((row) => ({
            ...row,
            custom_field: <CustomCellRenderer row={row} theme={theme}/>
        }));
        setObjsWithCustomField(dataWithCustomField);
    }, [rows, theme]);


    const columns = useMemo<MRT_ColumnDef<IWasteCode>[]>(
        () => {
            const baseColumns: MRT_ColumnDef<IWasteCode>[] = [
                {
                    accessorKey: 'custom_field',
                    header: '',
                    enableColumnActions: false,
                    enableColumnFilter: false,
                    enableColumnOrdering: false,
                    enableSorting: false,
                },
                {
                    accessorKey: 'code',
                    header: t('waste_code'),
                },
                // {
                //     accessorKey: 'description',
                //     header: t('description'),
                // },
                // {
                //     accessorKey: 'physical_state',
                //     header: t('physical state'),
                // },
                {
                    accessorKey: 'disposal_code',
                    header: t('disposal code'),
                },
                {
                    accessorKey: 'balance_of_site',
                    header: t('balance of site'),
                    accessorFn: (row) => row.balance_of_site + " kg"
                },
                // {
                //     accessorKey: 'recovery_code',
                //     header: t('recovery code'),
                // },
                // {
                //     accessorKey: 'hazardous_class',
                //     header: t('hazardous class'),
                // },
                // {
                //     accessorKey: 'internal_notes',
                //     header: t('internal notes'),
                // },
                {
                    accessorKey: 'test_expire_at',
                    header: t('test expire at'),
                    accessorFn: (row) => (row.test_expire_at ? formatDateTimeToDateString(row.test_expire_at) : ''),
                },

            ];


            // if (doc) {
            //     baseColumns.push({
            //         accessorKey: 'doc_type',
            //         header: t('payment type'),
            //         accessorFn: (row) => row.payment_type.name,
            //     });
            // }
            //
            // if (!doc) {
            //     baseColumns.push({
            //         accessorKey: 'registry',
            //         header: t('registry'),
            //         accessorFn: (row) => row.doc?.fiscal_registry.registry?.business_name,
            //     });
            // }

            return baseColumns;
        },
        [t]
    );


    const table = useMaterialReactTable<IWasteCode>({
        columns,
        defaultColumn: {
            maxSize: 400,
            minSize: 80,
            size: 80,
        },
        data: objsWithCustomField ? objsWithCustomField : [],
        columnFilterDisplayMode: 'popover',
        globalFilterFn: 'contains',
        enableFullScreenToggle: false,
        enableFilters: true,
        enableColumnActions: false,
        enableDensityToggle: false,
        muiTableHeadCellProps: getMuiTableHeadCellProps(theme.palette.primary.main),
        muiTableBodyCellProps: getMuiTableBodyCellProps(theme),
        muiTablePaperProps: getMuiTablePaperProps,
        enablePagination: false,
        initialState: {
            density: 'compact',
            pagination: {pageSize: 25, pageIndex: 0},
        },
        mrtTheme: (theme) => ({
            baseBackgroundColor: theme.palette.backgroundAppGarageColor.main,

        }),
        muiTableBodyRowProps: getMuiTableBodyRowProps(theme),

        enableRowActions: true,
        renderRowActionMenuItems: ({closeMenu, row}) => [
            onSelect && onClose && (
                <MRT_ActionMenuItem
                    icon={<AdsClickIcon color="primary"/>}
                    key="select"
                    label={t("select")}
                    onClick={() => {
                        onSelect(row.original);
                        onClose();
                    }}
                    table={table}
                />),
            <MRT_ActionMenuItem
                icon={<Edit color="primary"/>}
                key="edit"
                label={t("edit")}
                onClick={(event) => {
                    setSelectedRow(row.original);
                    setIsEditFormOpen(true)
                    closeMenu()
                }}
                table={table}
            />,
            <MRT_ActionMenuItem
                icon={<Delete color="warning"/>}
                key="delete"
                label={t("delete")}
                onClick={() => {
                    setSelectedRow(row.original);
                    setDeleteConfirmationOpen(true)
                    closeMenu()
                }}
                table={table}
            />,
        ],
        renderTopToolbarCustomActions: ({table}) => (
            <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                <>
                    <Avatar sx={{
                        backgroundColor: 'white',
                        border: `2px solid ${theme.palette.primary.main}`,
                        marginRight: 1
                    }}>
                        <FaRecycle color={theme.palette.primary.main}/>
                    </Avatar>
                    <MuiReadOnlyBoxBody label={''} value={'waste_codes'}/>
                    <MRTMuiButton
                        icon={<AddIcon style={{color: theme.palette.primary.main, fontSize: '2rem'}}/>}
                        onClick={() => {
                            setSelectedRow(undefined);
                            setIsEditFormOpen(true)
                        }} label={'add'}/>
                </>
            </Box>
        ),
        enableExpandAll: false,
        displayColumnDefOptions: {
            'mrt-row-expand': {
                header: t('products_services'),
            },
        },
        muiDetailPanelProps: () => ({
            sx: (theme) => ({
                backgroundColor: theme.palette.backgroundAppGarageColor.dark
            }),
        }),
        muiExpandButtonProps: ({row, table}) => ({
            onClick: () => table.setExpanded({[row.id]: !row.getIsExpanded()}),
            sx: {
                transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
                transition: 'transform 0.2s',
                color: theme.palette.primary.main,
            },
        }),
        renderDetailPanel: ({row}) =>
            <MuiBorderedBox>
                {/*<ArticleTable articles={row.original.articles} person={row.original}/>*/}
                <OpenLoadsTable my_company_site={my_company_site} open_loads={row.original.open_loads}/>
            </MuiBorderedBox>
    });


    return <>
        {isLoading ? <Loading/> :
            <>
                {onClose &&
                    <Grid item xs={12} style={{textAlign: 'center'}}>
                        <Box className={'mt-2 gap-x-2 mb-2'}>
                            <MuiFabButtonBack onClick={onClose}/>
                        </Box>
                    </Grid>
                }

                <MaterialReactTable table={table}/>

                <MuiAlertDialog
                    open={isDeleteConfirmationOpen}
                    title="delete_confirmation"
                    content="ask_for_delete"
                    onClose={handleCancelDelete}
                    onConfirm={handleConfirmDelete}
                />

                <MuiModal
                    top="50%"
                    left="50%"
                    width={'80vh'}
                    open={isEditFormOpen}
                    onClose={() => setIsEditFormOpen(false)}
                >

                    <WastyCodeForm
                        my_company_site={my_company_site}
                        objToEdit={selectedRow || null}
                        onClose={() => setIsEditFormOpen(false)}
                    />
                </MuiModal>


                {isFetching &&
                    <MuiFetchingSpinner text="Fetching data..."/>
                }
            </>}
    </>;
};

export default WasteCodeTable;
