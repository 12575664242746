import React, {useEffect, useState} from 'react';
import {IDocPayment} from "../../../../../../utils/interfaces/my_documents/doc_payment/IDocPayment";
import {useTranslation} from "react-i18next";
import useApiCreateDocPayment from "./api-hooks/useApiCreateDocPayment";
import useApiUpdateDocPayment from "./api-hooks/useApiUpdateDocPayment";
import useApiGetDocPayment from "./api-hooks/useApiGetDocPayment";
import {Box} from "@mui/material";
import MuiInputTextField from "../../../../../ui/mui/mui-input/MuiInputTextField";
import MuiFabButtonBack from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import MuiFabButtonSaveAs from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSaveAs";
import MuiFabButtonSave from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSave";
import {IDocDetail} from "../../../../../../utils/interfaces/my_documents/doc/IDocDetail";


interface Props {
    objToEdit: IDocPayment | null | undefined;
    onClose: () => void;
    onSelect?: (obj: IDocPayment | undefined) => void,
    doc: IDocDetail,
}

const DocPaymentForm: React.FC<Props> = ({objToEdit, onClose, onSelect, doc}) => {
    const {t} = useTranslation()

    const [value, setValue] = useState<number>();
    const [paymentDate, setPaymentDate] = useState<string>();
    const [description, setDescription] = useState<string>();
    const [lockedAt, setLockedAt] = useState<string>();
    const [manuallyModifiedAt, setManuallyModifiedAt] = useState<string>();

    const createMutation = useApiCreateDocPayment();
    const updateMutation = useApiUpdateDocPayment();

    const {
        data: detailedObj,
    } = useApiGetDocPayment(objToEdit?.id);


    useEffect(() => {
        if (detailedObj) {
            setValue(detailedObj.value);
            setPaymentDate(detailedObj.payment_date);
            setDescription(detailedObj.description);
            setLockedAt(detailedObj.locked_at);
            setManuallyModifiedAt(detailedObj.manually_modified_at);
        }
    }, [detailedObj]);


    const handleSubmit = async () => {
        try {
            const payload = {
                value: value,
                payment_date: paymentDate,
                description: description,
                locked_at: lockedAt ? lockedAt : null,
                manually_modified_at: manuallyModifiedAt ? manuallyModifiedAt : null,
                doc_id: doc.id,
            };

            let newData;

            if (detailedObj !== null && detailedObj !== undefined) {
                newData = await updateMutation.mutateAsync({id: detailedObj.id, data: payload});
            } else {
                newData = await createMutation.mutateAsync(payload);
            }
            onClose();
            onSelect && onSelect(newData);

        } catch (error) {
        }
    }

    const [allRequirementAreSatisfied, setAllRequirementAreSatisfied] = useState(false);
    useEffect(() => {
        setAllRequirementAreSatisfied(false);
        if (value && paymentDate) {
            setAllRequirementAreSatisfied(true);
        }
    }, [paymentDate, value]);


    return (
        <Box sx={{padding: 2}}>
            <form>
                <MuiInputTextField
                    onChange={(e) => setValue(parseFloat(e.target.value))}
                    label='value'
                    type="number"
                    value={value || ''}
                    required={true}
                />

                <MuiInputTextField
                    onChange={(e) => setPaymentDate(e.target.value)}
                    label='payment_date'
                    type="date"
                    value={paymentDate || 0}
                    required={true}
                />

                <MuiInputTextField
                    onChange={(e) => setDescription(e.target.value)}
                    label='description'
                    type="text"
                    value={description || ''}
                    required={false}
                />

                {/*<MuiInputTextField*/}
                {/*    onChange={(e) => setLockedAt(e.target.value)}*/}
                {/*    label='locked_at'*/}
                {/*    type="date"*/}
                {/*    value={formatDateTimeForInput(lockedAt) || '0'}*/}
                {/*    required={false}*/}
                {/*/>*/}

                {/*<MuiInputTextField*/}
                {/*    onChange={(e) => setManuallyModifiedAt(e.target.value)}*/}
                {/*    label='manually_modified_at'*/}
                {/*    type="date"*/}
                {/*    value={formatDateTimeForInput(manuallyModifiedAt) || '0'}*/}
                {/*    required={false}*/}
                {/*/>*/}

                <Box style={{display: 'flex', justifyContent: 'center'}} className={'mt-2 gap-x-2'}>
                    <MuiFabButtonBack onClick={onClose}/>
                    {/*{objToEdit &&*/}
                    {/*    <MuiFabButtonDelete onClick={() => {*/}
                    {/*        setConfirmationOpen(true)*/}
                    {/*    }}/>}*/}
                    {objToEdit ?
                        <MuiFabButtonSaveAs onClick={handleSubmit} isDisabled={!allRequirementAreSatisfied}/>
                        :
                        <MuiFabButtonSave onClick={handleSubmit} isDisabled={!allRequirementAreSatisfied}/>}
                </Box>
            </form>
        </Box>
    );
};

export default DocPaymentForm;