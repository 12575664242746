import React from 'react';
import {Box} from "@mui/material";
import {IconContext} from "react-icons";

import {useTranslation} from "react-i18next";
import {IWastyCarrier} from "../../../../../utils/interfaces/special_wasty/wasty_carrier/IWastyCarrier";

// note_marco: customCellRenderer in hook example
const useCustomCellRenderer = (row: IWastyCarrier, theme: any, onOpenFiscalRegistryForm: () => void) => {
    const {t} = useTranslation()


    const handleSubmit = async (mutation: any) => {
        try {
            await mutation.mutateAsync({id: row.id});
        } catch (error) {
        }
    }

    const getIconsForCustomField = (row: IWastyCarrier, theme: any) => {
        const icons = [];

        icons.push(null)


        // icons.push(
        //     <MRTMuiButton
        //         small={true}
        //         icon={<IoHandLeftOutline
        //             color={row.manually_modified_at ? theme.palette.secondary.main : theme.palette.disabledColor.main}/>}
        //         onClick={() => handleSubmit(toggleManuallyModifiedAtMutation)}
        //         label='manually_modified_at'
        //     />
        // );
        //
        // icons.push(
        //     <MRTMuiButton
        //         small={true}
        //         icon={<FaLock color={row.locked_at ? theme.palette.secondary.main : theme.palette.disabledColor.main}/>}
        //         onClick={() => handleSubmit(toggleLockedAtMutation)}
        //         label='locked_at'
        //     />
        // );
        //
        //
        // if (!row.doc?.fiscal_registry.bank && row.payment_type.id === 2) {
        //     icons.push(
        //         <MRTMuiButton
        //             small={true}
        //             icon={<FaSkull className='animate-pulse text-red-600'/>}
        //             onClick={() => {
        //                 onOpenFiscalRegistryForm();
        //             }} label={'customer bank missing (click to edit customer FiscalRegistry)'}/>
        //     )
        // }

        return icons;
    };

    const renderIcons = () => {
        const icons = getIconsForCustomField(row, theme);
        return (
            <IconContext.Provider value={{size: "1.3rem"}}>
                <Box display="flex" flexDirection="row" sx={{gap: 0.5}}>
                    {icons}
                </Box>
            </IconContext.Provider>
        );
    };

    return {renderIcons};
};

export default useCustomCellRenderer;