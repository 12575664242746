import React, {useEffect, useMemo, useState} from 'react';
import {
    IOrderScheduleDetail
} from '../../../../../utils/interfaces/tannery_production/order_schedule/IOrderScheduleDetail';
import {
    MaterialReactTable,
    MRT_ActionMenuItem,
    MRT_ColumnDef,
    MRT_Row,
    MRT_RowSelectionState,
    useMaterialReactTable
} from 'material-react-table';
import customCellRenderer from "./customCellRenderer";
import {useTheme} from "@mui/material/styles";
import {
    getMuiTableBodyCellProps,
    getMuiTableBodyRowProps,
    getMuiTableHeadCellProps,
    getMuiTablePaperProps
} from "../../../../ui/mui/mui_react_table/MuiReactTableStyles";
import {formatDateTimeToDateString} from "../../../../../logics/helpers";
import {useTranslation} from "react-i18next";
import {Delete, Edit} from "@mui/icons-material";
import {GiCow} from "react-icons/gi";
import {FaBalanceScale, FaUnlock} from "react-icons/fa";
import {MdWorkspaces} from "react-icons/md";
import {PiRuler} from "react-icons/pi";
import useApiDeleteCompanyOrderSchedule from "./api-hooks/useApiDeleteCompanyOrderSchedule";
import {CustomAxiosError} from "../../../../../logics/api_config";
import {useAuth} from "../../../../../utils/contexts/auth-context";
import {useQueryClient} from "@tanstack/react-query";
import {useToast} from "../../../../../utils/contexts/toast-context";
import {useTanneryProduction} from "../../../../../utils/contexts/tannery_production/tannery_production-context";
import MuiModal from "../../../../ui/mui/mui_modal/MuiModal";
import OrderScheduleForm from "./OrderScheduleForm";
import MuiAlertDialog from "../../../../ui/mui/mui_modal/MuiAlertDialog";
import RawMaterialLinker from "./raw_material/RawMaterialLinker";
import WorkingsLinker from "./workings/WorkingsLinker";
import FinalMeasurementForm from "./final_measurement/FinalMeasurementForm";
import {Avatar, Box, FormControlLabel, Switch} from "@mui/material";
import DyeTable from "./dye/DyeTable";
import MuiReadOnlyBoxBody from "../../../../ui/mui/mui-display-data/MuiReadOnlyBoxBody";
import {useLocation} from "react-router-dom";
import {AiOutlineBorderlessTable} from "react-icons/ai";
import {IColor} from "../../../../../utils/interfaces/tannery_production/articles/IColor";
import AddIcon from "@mui/icons-material/Add";
import MRTMuiButton from "../../../../ui/mui/mui_react_table/MRTMuiButton";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import {IPeople} from "../../../../../utils/interfaces/service/people/IPeople";
import {IArticle} from "../../../../../utils/interfaces/tannery_production/articles/IArticle";
import PeopleSelect from "../../service/people/PeopleSelect";
import ArticleSelect from "../people_articles/articles/ArticleSelect";
import MuiFabButtonBack from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import ColorSelect from "../people_articles/articles/colors/ColorSelect";
import useApiCompanyOrderScheduleProductChange from "./api-hooks/useApiCompanyOrderScheduleProductChange";
import {FaLock} from "react-icons/fa6";
import useApiManuallyClosedToggleCompanyOrderSchedule from "./api-hooks/useApiManuallyClosedToggleCompanyOrderSchedule";


interface Props {
    color?: IColor;
    orders_schedule?: IOrderScheduleDetail[];
    handleScopeToggle?: () => void;
    scope?: 'current' | 'historical'
}

interface IOrderScheduleDetailWithCustomField extends IOrderScheduleDetail {
    custom_field: JSX.Element;
}

const OrderScheduleTable = ({
                                orders_schedule: initialOrdersSchedule,
                                handleScopeToggle,
                                scope,
                                color: initialColor,
                            }: Props) => {
    const {selectedCompany} = useAuth();
    const {displayError, displayMessage} = useToast()
    const [ordersSchedule, setOrdersSchedule] = useState(initialOrdersSchedule);
    const theme = useTheme();
    const icon_color = theme.palette.primary.main
    const {t} = useTranslation()
    const queryClient = useQueryClient();
    const {
        selectedCompanyOrderSchedule,
        selectCompanyOrderSchedule,
        isRawMaterialOpen, selectIsRawMaterialOpen,
        isWorkingsOpen, selectIsWorkingsOpen,
        isDyeOpen, selectIsDyeOpen,
        isFinalMeasurementOpen, selectIsFinalMeasurementOpen,
    } = useTanneryProduction()

    const [selectedRow, setSelectedRow] = useState<IOrderScheduleDetail | undefined>()
    const [isColorSelectorOpen, setIsColorSelectorOpen] = useState(false);
    const [newSelectedPerson, setNewSelectedPerson] = useState<IPeople | null | undefined>()
    const handleNewPersonChange = async (obj: IPeople | null) => {
        setNewSelectedPerson(obj);
    }
    const [newSelectedArticle, setNewSelectedArticle] = useState<IArticle | null | undefined>()
    const handleNewArticleChange = async (obj: IArticle | null | undefined) => {
        setNewSelectedArticle(obj);
    }

    const manuallyClosedOrderMutation = useApiManuallyClosedToggleCompanyOrderSchedule(selectedCompany?.company_token)

    const updateMutation = useApiCompanyOrderScheduleProductChange(selectedCompany?.company_token)
    const handleNewColorChange = async (newColor: IColor | undefined) => {
        if (newColor === undefined) {
            return
        }

        if (initialColor) {
            const updatePromises = table.getSelectedRowModel().rows.map(obj => updateMutation.mutateAsync({
                product_id: newColor.id,
                id: obj.original.id,
            }));

            try {
                await Promise.all(updatePromises);
            } catch (error) {
            }
        }


        setIsColorSelectorOpen(false)
        await queryClient.invalidateQueries({queryKey: ['getCompanyOrderScheduleByColor']});
        await queryClient.invalidateQueries({queryKey: ['getParentsCompanyOrderSchedule']});
        await queryClient.invalidateQueries({queryKey: ['getCompanyOrderSchedule']});
        await queryClient.invalidateQueries({queryKey: ['getPeopleArticles']});
    }

    const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

    const [color, setColor] = useState<IColor | undefined>()
    useEffect(() => {
        setColor(initialColor);
    }, [initialColor]);

    const location = useLocation();
    const isInTanneryProduction = (location.pathname.startsWith('/apps/tannery_production') && !location.pathname.startsWith('/apps/tannery_production/articles'));
    const isLwgDashBoard = (location.pathname.startsWith('/apps/lwg_dashboard/production_report'));

    const handleOpenRawMaterial = (row: MRT_Row<IOrderScheduleDetail>) => {
        selectCompanyOrderSchedule(row.original)
        setSelectedRow(row.original);
        selectIsRawMaterialOpen(true)
    }

    const handleOpenWorkins = (row: MRT_Row<IOrderScheduleDetail>) => {
        selectCompanyOrderSchedule(row.original)
        selectIsWorkingsOpen(true)
    }

    const handleOpenDyeing = (row: MRT_Row<IOrderScheduleDetail>) => {
        selectCompanyOrderSchedule(row.original)
        selectIsDyeOpen(true)
    }

    const handleOpenFinalMeasurement = (row: MRT_Row<IOrderScheduleDetail>) => {
        selectCompanyOrderSchedule(row.original)
        selectIsFinalMeasurementOpen(true)
    }

    const handleDeleteOrder = (row: MRT_Row<IOrderScheduleDetail>) => {
        selectCompanyOrderSchedule(row.original)
        setDeleteConfirmationOpen(true)
    }
    const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const deleteMutation = useApiDeleteCompanyOrderSchedule(selectedCompany?.company_token, selectedCompanyOrderSchedule?.color.id, selectedCompanyOrderSchedule?.id);
    const {mutateAsync: apiDeleteOrder} = deleteMutation;

    const removeDeletedRow = (deletedOrderId: number | undefined) => {
        if (ordersSchedule) {
            const updatedOrdersSchedule = ordersSchedule?.filter(
                (order) => order.id !== deletedOrderId
            );
            setOrdersSchedule(updatedOrdersSchedule);
        }
    };

    const handleConfirmDelete = async () => {
        try {
            await apiDeleteOrder({
                companyToken: selectedCompany?.company_token,
                color_id: selectedCompanyOrderSchedule?.color.id,
                pk: selectedCompanyOrderSchedule?.id
            });
            displayMessage('Order deleted successfully');

        } catch (error) {
            const axiosError = error as CustomAxiosError;
            if (axiosError.response && axiosError.response.data && axiosError.response.data.detail) {
                displayError(axiosError.response.data.detail);
            } else {
                displayError('An error occurred while deleting the Order');
            }
        }
        setDeleteConfirmationOpen(false);
        removeDeletedRow(selectedCompanyOrderSchedule?.id);
        await queryClient.invalidateQueries({queryKey: ['getCompanyOrderScheduleByColor']});
        await queryClient.invalidateQueries({queryKey: ['getCompanyOrderSchedule']});
        await queryClient.invalidateQueries({queryKey: ['getParentsCompanyOrderSchedule']});
        await queryClient.invalidateQueries({queryKey: ['useApiGetHistoricalCompanyOrderSchedule']});
        await queryClient.invalidateQueries({queryKey: ['getPeopleArticles']});
    };
    const handleCancelDelete = () => {
        setDeleteConfirmationOpen(false);
    };

    const [isOrderFormOpen, setIsOrderFormOpen] = useState<boolean>(false)

    useEffect(() => {
        setOrdersSchedule(initialOrdersSchedule);
    }, [initialOrdersSchedule]);


    const [ordersScheduleWithCustomField, setOrdersScheduleWithCustomField] = useState<IOrderScheduleDetailWithCustomField[] | undefined>();
    useEffect(() => {
        const dataWithCustomField: IOrderScheduleDetailWithCustomField[] | undefined = ordersSchedule?.map((order) => ({
            ...order,
            custom_field: customCellRenderer(order, theme),
            formatted_estimated_delivery_date: formatDateTimeToDateString(order.estimated_delivery_date),
        }));
        setOrdersScheduleWithCustomField(dataWithCustomField);
    }, [ordersSchedule]);


    const columns = useMemo<MRT_ColumnDef<IOrderScheduleDetail>[]>(
        () => [
            {
                accessorKey: 'custom_field',
                header: '',
                enableColumnActions: false,
                enableColumnFilter: false,
                enableColumnOrdering: false,
                enableSorting: false,
            },
            {
                accessorKey: 'order_id',
                header: t('order_id'),
                filterFn: 'contains',
            },
            {
                accessorKey: 'order_info',
                header: t('order_info'),
                filterFn: 'contains',
            },
            {
                accessorKey: 'people_name',
                header: t('people_name'),
                filterFn: 'contains',
            },
            {
                accessorKey: 'article_name',
                header: t('article'),
                filterFn: 'contains',
            },
            {
                accessorKey: 'color.color_name',
                header: t('color'),
                filterFn: 'contains',
            },
            {
                accessorKey: 'quantity',
                header: t('quan'),
                filterFn: 'between',
                enableGlobalFilter: false,
            },
            {
                accessorKey: 'formatted_estimated_delivery_date',
                header: 'etd',
                enableColumnFilter: false,
                sortingFn: 'datetime',
                dateSetting: {locale: "it-IT"},
                enableGlobalFilter: false,
            },
        ],
        []
    );


    const table = useMaterialReactTable<IOrderScheduleDetail>({
        columns,
        defaultColumn: {
            maxSize: 400,
            minSize: 80,
            size: 80,
        },
        data: ordersScheduleWithCustomField ? ordersScheduleWithCustomField : [],
        columnFilterDisplayMode: 'popover',
        globalFilterFn: 'contains',
        enableFullScreenToggle: false,
        muiTableHeadCellProps: getMuiTableHeadCellProps(theme.palette.primary.main),
        muiTableBodyCellProps: getMuiTableBodyCellProps(theme),
        muiTablePaperProps: getMuiTablePaperProps,
        initialState: {density: 'compact', pagination: {pageSize: isLwgDashBoard ? 10 : 25, pageIndex: 0}},
        mrtTheme: (theme) => ({
            baseBackgroundColor: theme.palette.backgroundAppGarageColor.main,

        }),
        muiTableBodyRowProps: getMuiTableBodyRowProps(theme),
        enablePagination: isInTanneryProduction || isLwgDashBoard,
        enableGlobalFilter: isInTanneryProduction,
        enableDensityToggle: isInTanneryProduction,
        enableRowActions: true,
        renderRowActionMenuItems: ({closeMenu, row}) => [
            <MRT_ActionMenuItem
                icon={<Edit color="primary"/>}
                key="edit"
                label={t("edit")}
                onClick={() => {
                    setColor(row.original.color);
                    setSelectedRow(row.original);
                    setIsOrderFormOpen(true);
                    closeMenu();
                }}
                table={table}
                // disabled={scope === 'historical'}
            />,
            <MRT_ActionMenuItem
                icon={<Delete color="warning"/>}
                key="delete"
                label={t("delete")}
                onClick={() => {
                    handleDeleteOrder(row);
                    closeMenu();
                }}
                table={table}
                // disabled={scope === 'historical'}
            />,
            <MRT_ActionMenuItem
                icon={<GiCow color={icon_color}/>}
                key="tannery_raw_material"
                label={t("tannery_raw_material")}
                onClick={() => {
                    handleOpenRawMaterial(row);
                    closeMenu();
                }}
                table={table}
                // disabled={scope === 'historical'}
            />,
            <MRT_ActionMenuItem
                icon={<FaBalanceScale color={icon_color}/>}
                key="weight"
                label={t("weight")}
                onClick={() => {
                    handleOpenDyeing(row);
                    closeMenu();
                }}
                table={table}
                // disabled={scope === 'historical'}
            />,
            <MRT_ActionMenuItem
                icon={<MdWorkspaces color={icon_color}/>}
                key="workings"
                label={t("workings")}
                onClick={() => {
                    handleOpenWorkins(row);
                    closeMenu();
                }}
                table={table}
                // disabled={scope === 'historical'}
            />,
            <MRT_ActionMenuItem
                icon={<PiRuler color={icon_color}/>}
                key="final_measurement"
                label={t("final_measurement")}
                onClick={() => {
                    handleOpenFinalMeasurement(row);
                    closeMenu();
                }}
                table={table}
                disabled={row.original.has_open_workings}
            />,
            <MRT_ActionMenuItem
                icon={row.original.manually_closed_at ? <FaUnlock color={icon_color}/> :
                    <FaLock color={icon_color}/>}
                key="manually_closed_toggle"
                label={row.original.manually_closed_at ? t("manually open the order") : t("manually close the order")}
                onClick={() => {
                    manuallyClosedOrderMutation.mutateAsync({id: row.original.id});
                    closeMenu();
                }}
                table={table}
                disabled={row.original.has_final_measurement}
            />

        ],
        renderTopToolbarCustomActions: ({table}) => (
            <Box sx={{display: 'flex', gap: '1rem', p: '4px', alignItems: 'center'}}>

                <Avatar sx={{
                    backgroundColor: 'white',
                    border: `2px solid ${theme.palette.primary.main}`,
                    marginRight: 0
                }}>
                    <AiOutlineBorderlessTable color={theme.palette.primary.main}/>
                </Avatar>

                <MuiReadOnlyBoxBody label={''} value={'order_schedule'}/>
                {scope &&
                    <>
                        <FormControlLabel control={
                            <Switch
                                color="primary"
                                onChange={handleScopeToggle}
                            />
                        } label={t(scope)}
                                          style={{color: theme.palette.primary.main}}
                        />,

                    </>
                }
                {color &&
                    <MRTMuiButton
                        icon={<AddIcon style={{color: theme.palette.primary.main, fontSize: '2rem'}}/>}
                        onClick={() => {
                            selectCompanyOrderSchedule(undefined);
                            setSelectedRow(undefined)
                            setIsOrderFormOpen(true)
                        }} label={'add_order'}/>}

                {table.getSelectedRowModel().rows.length > 0 &&
                    <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                        <MRTMuiButton
                            bigAtRender={true}
                            icon={<NorthEastIcon style={{color: theme.palette.primary.main, fontSize: '2rem'}}/>}
                            onClick={() => {
                                setIsColorSelectorOpen(true)
                            }} label={t('move to another color')}/>
                    </Box>
                }
            </Box>
        ),
        enableRowSelection: !isInTanneryProduction,
        positionToolbarAlertBanner: 'none',
        onRowSelectionChange: setRowSelection,
        state: {rowSelection},

    });

    return <>
        {!isRawMaterialOpen && !isWorkingsOpen &&
            <>
                <MaterialReactTable table={table}/>
                <MuiAlertDialog
                    open={isDeleteConfirmationOpen}
                    title="delete_confirmation"
                    content="ask_for_delete"
                    onClose={handleCancelDelete}
                    onConfirm={handleConfirmDelete}
                />

                {/*<MuiModal*/}
                {/*    top="50%"*/}
                {/*    left="50%"*/}
                {/*    width={'50vh'}*/}
                {/*    open={isOrderFormOpen}*/}
                {/*    onClose={() => setIsOrderFormOpen(false)}*/}
                {/*>*/}
                {/*    <OrderScheduleForm*/}
                {/*        color={selectedCompanyOrderSchedule?.color}*/}
                {/*        companyOrderScheduleToEdit={selectedCompanyOrderSchedule ? selectedCompanyOrderSchedule : null}*/}
                {/*        onClose={() => setIsOrderFormOpen(false)}/>*/}
                {/*</MuiModal>*/}

                <MuiModal
                    top="50%"
                    left="50%"
                    width={'45vw'}
                    open={isDyeOpen}
                    onClose={() => selectIsDyeOpen(false)}
                >
                    {/*<DyeList*/}
                    {/*    onClose={() => selectIsDyeOpen(false)}/>*/}
                    <DyeTable onClose={() => {
                        selectIsDyeOpen(false)
                    }}/>
                </MuiModal>

                <MuiModal
                    top="50%"
                    left="50%"
                    width={'95vw'}
                    open={isWorkingsOpen}
                    onClose={() => selectIsWorkingsOpen(false)}
                >
                    <WorkingsLinker
                        isOpen={isWorkingsOpen}
                        onClose={() => selectIsWorkingsOpen(false)}/>
                </MuiModal>

                <MuiModal
                    top="50%"
                    left="50%"
                    width={'45vw'}
                    open={isFinalMeasurementOpen}
                    onClose={() => selectIsFinalMeasurementOpen(false)}
                >
                    <FinalMeasurementForm measureToEdit={selectedCompanyOrderSchedule?.final_measurement}
                                          scope={scope}
                                          onClose={() => selectIsFinalMeasurementOpen(false)}/>
                </MuiModal>


            </>}


        <RawMaterialLinker
            isOpen={isRawMaterialOpen}
            onClose={() => selectIsRawMaterialOpen(false)}/>

        <WorkingsLinker
            isOpen={isWorkingsOpen}
            onClose={() => selectIsWorkingsOpen(false)}/>


        <MuiModal top="50%" left="50%" width={'50vh'} open={isOrderFormOpen}
                  onClose={() => setIsOrderFormOpen(false)}>
            <OrderScheduleForm
                companyOrderScheduleToEdit={selectedRow ? selectedRow : null}
                color={color}
                onClose={() => setIsOrderFormOpen(false)}
            />
        </MuiModal>

        <MuiModal top="50%" left="50%" width={'50vh'} open={isColorSelectorOpen} disableBackdropClick={true}
                  onClose={() => setIsColorSelectorOpen(false)}>
            <PeopleSelect people={newSelectedPerson ? newSelectedPerson : undefined} onChange={handleNewPersonChange}
                          className={''}
                          category_person={'customer'}/>
            {newSelectedPerson &&
                <ArticleSelect customer_id={newSelectedPerson.id}
                               obj={newSelectedArticle ? newSelectedArticle : undefined}
                               onChange={handleNewArticleChange}
                               className={''}/>
            }
            {newSelectedPerson && newSelectedArticle &&
                <ColorSelect article_id={newSelectedArticle.id} obj={undefined} onChange={handleNewColorChange}
                             className={''}/>
            }
            <Box style={{display: 'flex', justifyContent: 'center'}} className={'mt-2 gap-x-2'}>
                <MuiFabButtonBack onClick={() => setIsColorSelectorOpen(false)}/>
            </Box>
        </MuiModal>


    </>;
};

export default OrderScheduleTable;
