import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import {IWasteCode} from "../../../../../../utils/interfaces/special_wasty/waste_code/IWasteCode";


const useApiGetWasteCode = (pk: number | undefined): UseQueryResult<IWasteCode, CustomAxiosError> => {
    const {selectedCompany} = useAuth();

    const {get} = useApiCalls()

    async function doGet(pk: number | undefined): Promise<IWasteCode> {
        try {
            const response = await get(`/api/company/${selectedCompany?.company_token}/special_wasty/waste_code/${pk}`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetWasteCode", pk],
        queryFn: () => doGet(pk),
        enabled: !!pk,
    })

};

export default useApiGetWasteCode