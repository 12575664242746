import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";
import {IWarehouseProductMovement} from "../../../../../../../utils/interfaces/my_warehouse/IWarehouseProductMovement";


const useApiUpdateManualWarehouseProductMovement = (warehouse_id: number, product_id: number | undefined): UseMutationResult<IWarehouseProductMovement, CustomAxiosError, {
    pk: number | undefined;
    data: any
}, unknown> => {
    const queryClient = useQueryClient();
    const {selectedCompany} = useAuth()

    const {put} = useApiCalls()

    async function doUpdate(
        pk: number | undefined,
        data: any): Promise<IWarehouseProductMovement> {
        try {
            const response = await put(`/api/company/${selectedCompany?.company_token}/my_warehouse/${warehouse_id}/products/${product_id}/movements/manual_movement/${pk}/`, data);
            return response.data as IWarehouseProductMovement;
        } catch (error) {
            throw error;
        }
    }

    return useMutation<IWarehouseProductMovement, CustomAxiosError, {
        pk: number | undefined;
        data: any
    }, unknown>({
        mutationFn: async ({pk, data}) => {
            return await doUpdate(pk, data);
        },
        onSuccess: async (data) => {
            await queryClient.invalidateQueries({queryKey: ['useApiGetManualWarehouseProductMovement', warehouse_id, product_id, data.id]});
            await queryClient.invalidateQueries({queryKey: ["useApiGetWarehouseProductMovements", warehouse_id, product_id]});
            await queryClient.invalidateQueries({queryKey: ["useApiGetWarehouseProducts", warehouse_id]});
            await queryClient.invalidateQueries({queryKey: ["useApiGetWarehouseParentProducts", warehouse_id]});
        },
    });
};


export default useApiUpdateManualWarehouseProductMovement;